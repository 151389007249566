import { Pipe, PipeTransform } from '@angular/core';

import { getNestedProperty } from '../../utils/get-nested-property.util';

@Pipe({
  name: 'filterBy',
  standalone: true,
})
export class FilterByPipe implements PipeTransform {
  transform<T>(arr: T[], propPath: string, searchTerms: string[]): T[] {
    if (!arr?.length || !searchTerms?.length) {
      return arr;
    }

    const lowerCasedSearchTerms = searchTerms.map((i) => i.trim().toLowerCase());
    return arr.filter((item: T) => {
      const propValue = getNestedProperty(item, propPath);
      return propValue && lowerCasedSearchTerms.some((term) => `${propValue}`.trim().toLowerCase().includes(term));
    });
  }
}
